#sponsors {
    background-color: var(--bg);
    padding: 100px 30px;
}

#sponsors #container {
    max-width: 1000px;
    margin: 0px auto;
}

#sponsors #container #images {
    width: 100%;
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    /* gap: 100px; */
}

#sponsors #container #images .row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
}

#sponsors #container #images img {
    max-width: min(200px, 100%);
}


@media (max-width: 650px) {
    #sponsors #container #images .row {
        flex-direction: column;
        gap: 50px;
    }
}