#alumni-page {
    min-height: 100vh;
    background-color: var(--bg);
    background-image: url("../../images/background_alumni.png");
    background-attachment: fixed;
    background-size: cover;
    color: var(--color);
}

#alumni-page #container {
    padding: 40px 30px;  
    max-width: 1000px;
    margin: 0px auto;
}

#alumni-page #container h2 {
    text-align: center;
    margin: 0px 0px 100px 0px;
}

#alumni-page #cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

#alumni-page #cards .card {
    border-radius: 8px;
    border: 3px solid var(--accent-dark);
    padding: 60px 20px;
    width: 100%;
    max-width: 95vw;
    background-color: var(--dark-bg-darker);

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;   
}


#alumni-page #cards .card img {
    width: 100px;
    border-radius: 50%;
    margin: 0px 0px 40px 0px;
}

#alumni-page #cards .card h4 {
    font-size: 22px;
    text-align: center;
    padding: 10px 0px;
}

#alumni-page #cards .card h5 {
    font-size: 18px;
    text-align: center;
    padding: 10px 0px;
}

#alumni-page #cards .card p {
    text-align: center;
}



@media (max-width: 600px) {
    #alumni-page #container {
        padding: 40px 10px;
    }

    #alumni-page #container #cards {
        grid-template-columns: 1fr;
    }
    
    #alumni-page #cards .card {
        flex-direction: row;
        gap: 30px;
        padding: 20px;
    }

    #alumni-page #cards .card h4,
    #alumni-page #cards .card h5 {
        text-align: left;
    }

    #alumni-page #cards .card h4 {
        font-size: 20px;
    }

    #alumni-page #cards .card h5 {
        font-size: 16px;
    }

    #alumni-page #cards .card img {
        margin: 0px;
    }
}