body {
    width: 100vw;
    height: 100vh;
}

#parallax {
    height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    padding: 0px 20px;
}


#parallax #content {
    max-width: 900px;
    border-radius: 10px;
    padding: 40px;
    background-color: #00000077;
    color: var(--light-text);
}

#parallax #content h1 {
    color: var(--accent-light);
}

#parallax #content p {
    text-align: justify;
}

@media (max-width: 550px) {
    #parallax #content p {
        font-size: 17px;
    }
}