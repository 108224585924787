#vision-page {
    background-color: var(--bg);
    background-image: url('../../images/about_bg.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
}

#vision-page #nav-placeholder {
    background-color: var(--dark-bg);
    height: 80px;
    width: 100%;
    position: sticky;
    top: 0px;
    left: 0px;
    z-index: 5;
}

#vision-page #container {
    max-width: 800px;
    margin: 0px auto;
    padding: 30px;
    /* border: 0px solid var(--accent-transparent);
    border-width: 0px 2px 0px 2px; */
}

#vision-page #container .section {
    padding: 20px 40px;
    margin: 30px 0px;
    border-radius: 5px;
    background-color: #000000aa;
    /* clip-path: polygon(0% 0%, 100% 0%, 100% calc(100% - 80px), 50% 100%, 0% calc(100% - 80px)); */
}

#vision-page #container .section:last-child {
    clip-path: none;
}

#vision-page #container .section h1 {
    color: var(--accent-light);
    font-size: 32px;
    margin: 20px 0px;
}

#vision-page #container .section p {
    margin: 30px 0px;
    text-align: justify;
}