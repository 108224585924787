#sponsor-page {
    background-color: var(--bg);
}

#sponsor-page #container {
    max-width: 900px;
    margin: 0px auto;
    padding: 50px 20px 0px 20px;
}

#sponsor-page img {
    width: 90%;
}

#sponsor-page #account {
    margin: 0px auto;
    color: var(--color);
    font-size: 20px;
}

#sponsor-page #account div:last-child {
    font-weight: 700;
    color: var(--accent-light);
}



#sponsor-page #links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 15px;

}

#sponsor-page #links a {
    font-size: 20px;
    color: var(--color);
    text-decoration: none;
}

#sponsor-page #links a div {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 10px 20px;

    border: 3px solid var(--accent);
    border-radius: 100px;
    transition: 0.5s;
}

#sponsor-page #links a div:hover {
    background-color: var(--light-bg);
    color: var(--accent-dark);
}

#sponsor-page #links a div span {
    font-size: 30px;
}

@media (max-width: 500px) {
    #sponsor-page #links {
        flex-direction: column;
        align-items: stretch;
    }
}

#sponsor-page #links-caption {
    color: var(--color);
    font-size: 22px;
    padding-top: 50px;
}