#gallery {
    background-color: var(--bg);
    padding: 100px 0px 150px 0px;
    overflow: hidden;
}

#gallery h1 {
    margin: 0px;
}

#gallery #title {
    display: flex;
    align-items: center;
    padding: 0px 30px;
    gap: 20px;
    rotate: -10deg;
}

#gallery #images {
    rotate: -10deg;
}

#gallery #images #filmstrip {
    background-image: url(../../../images/film.png);
    background-size: contain;
    height: 20px;
    width: 500%;
}

#gallery #images #inner {
    display: flex;
    background-color: black;
    height: 200px;
    width: 500%;
}

#gallery #images #inner img {
    height: 100%;
    padding: 0px 8px;
    filter: grayscale();
}