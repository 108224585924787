#team-page {
    background-color: var(--bg);
    color: var(--color);
}

#team-page #container {
    margin: 0px auto;
    max-width: 1200px;
    padding: 40px 20px;
}




#team-page #nav {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    margin: 60px 0px;
}

#team-page #nav .team-nav-item {
    cursor: pointer;
    padding: 10px 30px;
    border: 2px solid var(--accent);
    border-radius: 10px;
}

#team-page #nav .team-nav-item#active {
    background-color: var(--accent-transparent);
}

#team-page #nav .team-nav-item::after {
    content: "";
    display: block;
    width: 0%;
    height: 4px;
    background-color: var(--accent);
    position: absolute;
    top: calc(100% - 4px);
    left: 0;
    border-radius: 0px 0px 10px 10px;
    transition: all 0.4s;
}

#team-page #nav .team-nav-item:hover::after {
    width: 100%;
}

#team-page #nav .team-nav-item#active::after {
    width: 0%;
}



@media (max-width: 670px) {
    #team-page #nav {
        gap: 20px;
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    #team-page #nav {
        gap: 10px;
        font-size: 12px;
    }
}







#team-page .section {
    border-bottom: 2px dashed var(--accent);
    padding: 20px 0px 40px 0px;
}

#team-page .flex {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    padding: 20px 0px;
}

#team-page h2 {
    text-align: center;
    margin: 30px 0px;
}

#team-page h3 {
    font-size: calc(18px + 1vw);
    text-align: center;
    margin: 30px 0px;
}

#team-page #team-content {
    padding: 40px 0px;
    display: flex;
    justify-content: center;
    /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
    /* gap: 20px; */
}

#team-page .team-card, #team-page .team-details-card {
    border-radius: 8px;
    border: 3px solid var(--accent);
    /* background-color: var(--bg); */
    /* color: var(--color); */
    /* min-height: 300px; */
    min-width: 200px;
    padding: 60px 20px;
    width: 300px;
    max-width: 95vw;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* cursor: pointer; */
    /* transition: 0.15s; */
}

#team-page .team-details-card {
    padding: 30px 20px;
    width: 250px;
}

/* #team-page .team-card:hover {
    margin: -2px 0px 0px -2px;
    box-shadow: 1px 1px 10px var(--accent-light);
} */

#team-page .team-card img {
    width: 100px;
    border-radius: 50%;
    margin-bottom: 40px;
}

#team-page .team-details-card img {
    width: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
}

#team-page .team-card h4, #team-page .team-details-card h4 {
    font-size: 22px;
    text-align: center;
    padding: 10px 0px;
}

#team-page .team-card p {
    text-align: center;
}

#team-page .team-details-card .socials {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 5px;
}

#team-page .team-details-card .socials a {
    color: white;
}


#team-page #team-content ul {
    list-style-type: none;
    padding: 10px;
    min-width: min(100%, 650px);
}

#team-page #team-content ul li {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 15px 0px;
}

#team-page #team-content ul li span {
    font-size: 50px;
}